#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 0 64px;
  /* position: sticky !important;
  top: 0 !important; */
}

@media only screen and (max-width: 750px) {
  header {
    flex: 0 0 50px;
  }
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  height: 100%;
  padding-left: 5px;
  overflow: auto;
}

.pev-none {
  pointer-events: none;
}

.overflow-auto {
  overflow: auto;
}
.w-100 {
  width: 100%;
}
.w-120px{
  width: 120px;
}
.w-auto {
  width: auto!important;
}
.h-100 {
  height: 100%;
}
.pointer {
  cursor: pointer;
}
.minh-0 {
  min-height: 0;
}
.maxh-100 {
  max-height: 100%;
}
.text-transform-none {
  text-transform: none!important;
}
.d-flex {
  display: flex;
}

/*========== HELPERS ==================*/

.flex-1 {
  flex: 1;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute!important;
}
.ws-nowrap{
  white-space: nowrap;
}
.t-90{
 top: 90px;
}
.r-24{
  right: 24px;
}
.t-0 {
  top: 0;
}
.t-2 {
  top: 2px;
}
.t-4 {
  top: 4px;
}
.t-8 {
  top: 8px;
}
.r-0 {
  right: 0;
}
.r-2 {
  right: 2px;
}
.r-4 {
  right: 4px;
}
.r-8 {
  right: 8px;
}
.border-white {
  border-color: #fff!important;
}
.text-white {
  color: #fff!important
}
.text-gray {
  color: #9e9e9e!important;
}
.text-orange {
  color: #ff9800 !important;
}
.text-red {
  color: #f44336 !important;
}
.text-green {
  color: #4caf50 !important;
}
.text-blue {
  color: #2196f3 !important;
}
.text-blueGrey {
  color: #607d8b !important;
}
.bg-orange {
  background: #ff9800 !important;
}
.bg-red {
  background: #f44336 !important;
}
.bg-green {
  background: #4caf50 !important;
}
.bg-blue {
  background: #2196f3 !important;
}
.bg-blueGrey {
  background: #607d8b !important;
}

.mr-10 {
  margin-right: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mr-8 {
  margin-right: 8px !important;
}
.ml-8 {
  margin-left: 8px !important;
}
.mt-8 {
  margin-top: 8px !important;
}
.mb-8 {
  margin-bottom: 8px !important;
}
.mr-4 {
  margin-right: 4px !important;
}
.ml-4 {
  margin-left: 4px !important;
}
.mt-4 {
  margin-top: 4px !important;
}
.mb-4 {
  margin-bottom: 4px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pr-16 {
  padding-right: 16px !important;
}
.pl-16 {
  padding-left: 16px !important;
}
.pt-16 {
  padding-top: 16px !important;
}
.pb-16 {
  padding-bottom: 16px !important;
}
.pr-4 {
  padding-right: 4px !important;
}
.pl-4 {
  padding-left: 4px !important;
}
.pt-4 {
  padding-top: 4px !important;
}
.pb-4 {
  padding-bottom: 4px !important;
}
.pr-8 {
  padding-right: 8px !important;
}
.pl-8 {
  padding-left: 8px !important;
}
.pt-8 {
  padding-top: 8px !important;
}
.pb-8 {
  padding-bottom: 8px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}

.text-center {
  text-align: center;
}
.no-lh {
  line-height: unset !important;
}
.normal-lh {
  line-height: 1.4 !important;
}
.lh-0 {
  line-height: 0;
}
.bolder {
  font-weight: 500 !important;
}
.fg-1 {
  flex-grow: 1;
}
.border-dashed {
  border: 1px dashed #777;
}
.border-0 {
  border: none
}
.text-underline {
  text-decoration: underline;
}
.text-uppercase {
  text-transform: uppercase;
}
/*========== HELPERS ==================*/

.pinch-zoom-container {
  height: 100%;
}
